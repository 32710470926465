// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dev.api.mimedico.startapps.com.pe/api/',
  //apiUrl: 'http://192.168.1.3/api/',
  culqi: {
    secret_key: "sk_test_ImEo1MmPMNQCwO3U"
  },
  firebaseConfig: {
    apiKey: "AIzaSyCQk0OX6GR1NrEFaofwjANsNKpSt-rjEoQ",
    authDomain: "mimedico-4c91f.firebaseapp.com",
    databaseURL: "https://mimedico-4c91f.firebaseio.com",
    projectId: "mimedico-4c91f",
    storageBucket: "mimedico-4c91f.appspot.com",
    messagingSenderId: "747247308687",
    appId: "1:747247308687:web:81870dde3d0f5af14245fa",
    measurementId: "G-DVZ1VXKZZN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
