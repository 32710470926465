import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataChatResolverService } from './resolver/data-chat-resolver.service';
import { DataConsultationResolverService } from './resolver/data-consultation-resolver.service';
import { DataDoctorResolverService } from './resolver/data-doctor-resolver.service';
import { DataHistoryResolverService } from './resolver/data-history-resolver.service';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'account-callback',
    loadChildren: () => import('./account-callback/account-callback.module').then( m => m.AccountCallbackPageModule)
  },
  {
    path: 'searchdoctor',
    loadChildren: () => import('./components/searchdoctor/searchdoctor.module').then( m => m.SearchdoctorPageModule)
  },
  {
    path: 'doctordetail/:id',
    resolve: {
      special: DataDoctorResolverService
    },
    loadChildren: () => import('./components/doctordetail/doctordetail.module').then( m => m.DoctordetailPageModule)
  },
  {
    path: 'payconsultationcards/:id',
    resolve: {
      special: DataDoctorResolverService
    },
    loadChildren: () => import('./components/payconsultationcards/payconsultationcards.module').then( m => m.PayconsultationcardsPageModule)
  },
  {
    path: 'chatconsultant/:chatname',
    resolve: {
      special: DataChatResolverService
    },
    loadChildren: () => import('./components/chatconsultant/chatconsultant.module').then( m => m.ChatconsultantPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'resend-code',
    loadChildren: () => import('./resend-code/resend-code.module').then( m => m.ResendCodePageModule)
  },
  {
    path: 'videoconsultant/:id',
    resolve: {
      special: DataChatResolverService
    },
    loadChildren: () => import('./components/videoconsultant/videoconsultant.module').then( m => m.VideoconsultantPageModule)
  },
  {
    path: 'send-code2/:phone',
    loadChildren: () => import('./send-code2/send-code2.module').then( m => m.SendCode2PageModule)
  },
  {
    path: 'send-code',
    loadChildren: () => import('./send-code/send-code.module').then( m => m.SendCodePageModule)
  },
  {
    path: 'share',
    loadChildren: () => import('./account/share/share.module').then( m => m.SharePageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./account/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'motivo',
    loadChildren: () => import('./request/motivo/motivo.module').then( m => m.MotivoPageModule)
  },
  {
    path: 'viewimage',
    loadChildren: () => import('./components/viewimage/viewimage.module').then( m => m.ViewimagePageModule)
  },
  {
    path: 'cards/:id',
    loadChildren: () => import('./components/cards/cards.module').then( m => m.CardsPageModule)
  },
  {
    path: 'add-card',
    loadChildren: () => import('./components/add-card/add-card.module').then( m => m.AddCardPageModule)
  },
  {
    path: 'califica/:id',
    resolve: {
      special: DataConsultationResolverService
    },
    loadChildren: () => import('./components/califica/califica.module').then( m => m.CalificaPageModule)
  },
  {
    path: 'history/:chatname/:user_id',
    resolve: {
      special: DataHistoryResolverService
    },
    loadChildren: () => import('./request/history/history.module').then( m => m.HistoryPageModule)
  },{
    path: 'history/:user_id',
    loadChildren: () => import('./request/history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'history-detail',
    loadChildren: () => import('./request/history-detail/history-detail.module').then( m => m.HistoryDetailPageModule)
  },
  {
    path: 'history-consults',
    loadChildren: () => import('./request/history-consults/history-consults.module').then( m => m.HistoryConsultsPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
